<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div class="level-item">
                <h1 class="title is-1 page-title">Cookie Policy</h1>
            </div>
            <div class="box b-shdw-3">
                <p class="content" v-html="content.main"/>
            </div>
        </div>
    </transition>
</template>

<script>
    import { contentAPI } from "@/mixins/content-api";
    import { page } from "@/mixins/page";

    export default {
        name: "CookiePolicy",
        mixins: [ contentAPI, page ]
    }
</script>

<style lang="scss" scoped>
    .level-item {
        padding: 40px 0 40px 0;
    }

    .content /deep/ {
        h5 {
            color: $c2g_orange;
            margin-bottom: 8px !important;
            text-align: center;
        }
        p {
            margin: 0 0  0;
        }
    }
</style>